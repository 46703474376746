import { BackendProvider } from "@gooddata/sdk-ui";

import AppRouter from "./routes/AppRouter";
import { useAuth } from "./contexts/Auth";
import { WorkspaceListProvider } from "./contexts/WorkspaceList";
import "@gooddata/sdk-ui-geo/styles/css/main.css";
import { MapboxTokenProvider } from "@gooddata/sdk-ui-geo";

function App() {
    const { backend } = useAuth();
    const token =
        "pk.eyJ1IjoicGNvdGhlcnkiLCJhIjoiY2xubHZ6amFkMjI5ODJtcXg3a2Z6Z3NmcyJ9.ihBUYEieLj4lmgSBiM-Mmw";
    return (
        <BackendProvider backend={backend}>
            <MapboxTokenProvider token={token}>
                <WorkspaceListProvider>
                    <AppRouter />
                </WorkspaceListProvider>
            </MapboxTokenProvider>
        </BackendProvider>
    );
}

export default App;
