import React from "react";
import Page from "../components/Page";
import EmbeddedDashboard from "../components/Dashboards/DoAvpHourly";

const Overall: React.FC = () => {
    return (
        <Page>
            <EmbeddedDashboard />
        </Page>
    );
};

export default Overall;
