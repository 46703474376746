import "@gooddata/sdk-ui-dashboard/styles/css/main.css";
import { Dashboard } from "@gooddata/sdk-ui-dashboard";
import { idRef } from "@gooddata/sdk-model";

const dashboard = idRef("acWxqiE0xXzK");

function DoAvpHourlyBoard() {
    return <Dashboard dashboard={dashboard} />;
}

export default DoAvpHourlyBoard;
