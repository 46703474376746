import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./Header.module.scss";

const Links: React.FC = () => {
    return (
        <>
            <NavLink to={"/"} className={styles.Link} activeClassName={styles.LinkActive} exact>
                Company Summary
            </NavLink>
            <NavLink to="/Overall" className={styles.Link} activeClassName={styles.LinkActive}>
                Overall
            </NavLink>
            <NavLink to="/DoAvpHourly" className={styles.Link} activeClassName={styles.LinkActive}>
                Do-AVP Hourly Details
            </NavLink>
        </>
    );
};

export default Links;
